<template>
  <div class="wrapper">
    <div class="title">
      商家简介
    </div>
    <div class="content">
      <article>
        {{shopInfo.shopName}}
      </article>
      
    </div>
  </div>
</template>

<script>
  import {queryShopInfo} from "@/api/order";
export default {
  name: '',
  data() {
    return {
      shopInfo: {},
    };
  },
  mounted() {
    queryShopInfo(this.$route.query.shopId).then(response => {
      if(response.code==200){
        this.shopInfo = response.data;
      }
    });
  }
};
</script>
<style lang="less" scoped>
html{
  font-size: 37.5rem;
}
.wrapper{
  padding: 1.2rem 1.86rem;
}
.title{
  font-size: 16px;
  line-height: 2.12rem;
  color: #333;
  font-weight: bold;
}
.content{
  padding-top: 0.8rem;
  font-size: 0.93rem;
  line-height: 1.12rem;
}
article{
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
</style>
